.login-background {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.login-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f4fdfc;
  z-index: 1;
}

.input--error .ant-input {
  border-color: #cf4242;
}

.login-form {
  z-index: 3;
  position: absolute;
  top: 3%;
  left: 0;
  right: 0;
  margin: 40px;

  img {
    width: 200px;
    padding: 0 0 40px 0;
    display: block;
    margin: auto;
  }

  .form {
    width: 500px;
    padding: 40px;
    background-color: #fff;
    margin: 0 auto 30px auto;
    box-shadow: 3px 3px 9px #00000029;

    h2 {
      font-family: AvenirLTStdHeavy;
      text-align: center;
      color: #000;
      font-size: 24px;
      margin-bottom: 29px !important;
    }

    .login-input {
      h6 {
        font-family: AvenirLTStdBook;
        font-size: 16px;
        color: #000;
        font-weight: normal;
      }

      .ant-input::placeholder {
        color: #bbb;
      }
    }

    .login-input-error {
      .ant-input {
        border-color: #e31920;
      }
    }

    .login-checkbox {
      top: -2px !important;

      p {
        font-family: AvenirLTStdBook;
        font-size: 16px;
        color: #000;
        font-weight: normal;
      }
    }

    .error-message {
      font-family: AvenirLTStdBook;
      font-style: normal;
      font-size: 16px;
      color: #e31920;
    }

    .gray-divider {
      margin-bottom: 30px;
      background: #ddd;
    }

    .blue-divider {
      margin-top: 30px;
      margin-bottom: 30px;
      background: #050593;
    }

    .btn-primary {
      height: 38px;
      background-color: #050593;
      color: #fff;
      min-width: 200px;
      font-family: AvenirLTStdBook;
      border-radius: 4px;
      border: none;
    }

    @media (max-width: 576px) {
      h2 {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 600px) {
  .form {
    width: 100% !important;
  }
}

.ant-form-item-explain {
  color: #e31920 !important;
}

.ant-form-item {
  margin-bottom: 30px;
}
