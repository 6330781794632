.modal-primary-button {
  height: 36px !important;
  background-color: #050593 !important;
  color: #fff !important;
  font-size: 16px !important;
  min-width: 130px !important;
  border-radius: 4px !important;
  border: none !important;
}

.modal-secondary-button {
  height: 36px !important;
  background-color: #fff !important;
  color: #050593 !important;
  border-color: #050593 !important;
  font-size: 16px !important;
  min-width: 130px !important;
  border-radius: 4px !important;
  margin-right: 10px;
  border-width: 1px;
}
