@font-face {
  font-family: AvenirLTStdBook;
  src: url(./assets/fonts/AvenirLTStd-Book.otf);
}

@font-face {
  font-family: AvenirLTStdHeavy;
  src: url(./assets/fonts/AvenirLTStd-Heavy.otf);
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.btn-normal {
  background: #0326a0;
  border-radius: 24px;
  color: #fff;
  font-weight: 700;
  height: 50px !important;
}

.btn-outline {
  border: 1px solid #050593;
  color: #050593;
  font-weight: 700;
  height: 50px !important;
}

.btn-normal:hover {
  background: #0326a0;
  border-radius: 24px;
  color: #fff;
  font-weight: 700;
}

.btn-outline:hover {
  border: 1px solid #050593;
  color: #050593;
  font-weight: 700;
}

.ant-message-success {
  display: flex;
  align-items: center;
}

.secondary-button {
  height: 36px;
  background-color: #fff;
  color: #050593;
  border: 1px solid #050593;
  font-family: AvenirLTStdBook;
  font-size: 16px;
  min-width: 200px;
  border-radius: 4px;
}

.standard-font {
  font-size: 16px;
  font-family: AvenirLTStdBook;
  color: #000000;
  line-height: 1.2;
}

.standard-font-gray {
  font-size: 16px;
  font-family: AvenirLTStdBook;
  color: #707070;
  line-height: 1.2;
}

.ant-form-item-explain {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-family: AvenirLTStdBook;
}

.hms-menu-item {
  left: 140px;
}

.hms-menu-item > .ant-dropdown-menu-item:hover {
  background: none !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.p-10 {
  padding: 10px;
}

.red-border {
  border-color: #e31920 !important;
}
