.bg-container {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding: 63px 20px 20px 20px;

  .link-title {
    font-family: AvenirLTStdBook;
    font-size: 14px;
    line-height: 1.2;
    color: #1890ff;
    cursor: pointer;
  }

  .title {
    font-family: AvenirLTStdBook;
    font-size: 14px;
    line-height: 1.2;
    color: #000;
  }

  .bread-crumb-container {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .bread-crumb {
    font-family: AvenirLTStdBook;
    margin-right: 8px;
    margin-left: 8px;
    font-size: 14px;
    color: #000;
  }

  .bg-container-body {
    background-color: #fff;
    border: none;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px 4px #f2f2f2;
    padding: 40px;

    .gray-divider {
      margin-top: 30px;
      margin-bottom: 30px;
      background: #ddd;
    }

    .blue-divider {
      margin-top: 30px;
      margin-bottom: 30px;
      background: #050593;
    }

    .divider-margin-top {
      margin-top: 30px;
    }

    .mt-20 {
      margin-top: 20px;
    }

    .heading {
      font-family: AvenirLTStdHeavy;
      font-size: 20px;
      line-height: 1.2;
      color: #000000;
    }

    .sub-heading {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      line-height: 1.2;
      color: #000000;
    }

    .heading-link {
      font-family: AvenirLTStdHeavy;
      font-size: 20px;
      line-height: 1.2;
      color: #1890ff;
      cursor: pointer;
    }

    .text-link {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      line-height: 1.2;
      color: #1890ff;
      cursor: pointer;
    }

    .text-span-blue {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      line-height: 1.2;
      color: #1890ff;
    }

    .text-span-black {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      line-height: 1.2;
      color: #000000;
    }

    .secondary-button {
      height: 36px;
      background-color: #fff;
      color: #050593;
      border: 1px solid #050593;
      font-family: AvenirLTStdBook;
      font-size: 16px;
      min-width: 200px;
      border-radius: 4px;
    }

    .primary-button {
      height: 36px;
      background-color: #050593;
      color: #fff;
      font-family: AvenirLTStdBook;
      font-size: 16px;
      min-width: 200px;
      border-radius: 4px;
      border: none;
    }

    @media (max-width: 768px) {
      .primary-button-margin-md {
        margin-top: 30px;
      }
    }

    @media (max-width: 576px) {
      .primary-button-margin-bottom-sm {
        margin-bottom: 30px;
      }
    }

    @media (min-width: 576px) {
      .primary-filter-button-margin-sm {
        margin-top: 1.7rem;
      }
    }

    @media (max-width: 768px) {
      .secondary-button-margin-md {
        margin-top: 30px;
      }
    }
  }

  .coming-soon-heading {
    font-family: AvenirLTStdHeavy;
    font-size: 50px;
    color: #000000;
    line-height: 1.2;
  }

  .coming-soon-text {
    font-family: AvenirLTStdBook;
    font-size: 30px;
    color: #000000;
    line-height: 1.2;
  }
}

.loading-spinner {
  font-size: 25px;
  color: #050593;
}

.ant-select-selection-item-remove {
  margin-top: -4px;
}

.gray-divider {
  margin-top: 30px;
  margin-bottom: 30px;
  background: #ddd;
}
